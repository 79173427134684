/* Archivo fonts */
@font-face {
  font-family: ArchivoRegular;
  src: url(./assets/fonts/Archivo/Archivo-Regular.ttf);
}

@font-face {
  font-family: ArchivoMedium;
  src: url(./assets/fonts/Archivo/Archivo-Medium.ttf);
}

@font-face {
  font-family: ArchivoSemiBold;
  src: url(./assets/fonts/Archivo/Archivo-SemiBold.ttf);
}

@font-face {
  font-family: ArchivoBold;
  src: url(./assets/fonts/Archivo/Archivo-Bold.ttf);
}

/* Asap fonts */
@font-face {
  font-family: AsapRegular;
  src: url(./assets/fonts/Asap/Asap-Regular.ttf);
}
@font-face {
  font-family: AsapSemiBold;
  src: url(./assets/fonts/Asap/Asap-SemiBold.ttf);
}

@font-face {
  font-family: AsapMedium;
  src: url(./assets/fonts/Asap/Asap-Medium.ttf);
}

::-webkit-scrollbar {
  width: 0; 
}

a:-webkit-any-link {
  text-decoration: none;
}